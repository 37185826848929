<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="p-2">
          รายการอันดับ
        </div>
      </b-card-title>
      <b-table v-if="rankingList.length" small striped hover responsive show-empty class="position-relative items-center" :fields="fields"
        :items="rankingList">
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(id)="row">
          {{ row.item.id }}
        </template>
        <template #cell(level)="row">
          {{ row.item.level }}
        </template>
        <template #cell(cashback)="row">
          {{ row.item.cashback }}%
        </template>
        <template #cell(commission)="row">
          {{ row.item.commission }}%
        </template>
        <template #cell(point)="row">
          {{ row.item.point }}
        </template>
        <template #cell(dep)="row">
          {{ row.item.min_dep }}
        </template>
        <template #cell(turn)="row">
          {{ row.item.min_turn }}
        </template>
        <template #cell(actions)="row">
          <EditModal :data="row.item" @submit="submit"/>
        </template>
        <!-- <template #cell(status)="row">
          <b-badge v-if="row.item.status == 0" pill>รอ</b-badge>
          <b-badge v-if="row.item.status == 1" pill variant="info">ดำเนินการ</b-badge>
          <b-badge v-if="row.item.status == 2" pill variant="success">สำเร็จ</b-badge>
        </template> -->
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import EditModal from './modal.vue';
import {
  BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,

} from 'bootstrap-vue'

export default {
  components: {
    BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    EditModal
  },
  // props: ['items'],
  // emits: [],
  // filters: {
  //   convertDate(date) {
  //     return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
  //   },
  // },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'id', label: 'ID' },
      { key: 'level', label: 'ระดับ' },
      { key: 'cashback', label: 'รับเงินคืน' },
      { key: 'commission', label: 'ค่าคอมมิชชั่น' },
      { key: 'point', label: 'พอยท์' },
      { key: 'dep', label: 'ฝากสะสม' },
      { key: 'turn', label: 'เทิร์นสะสม' },
      { key: 'actions', label: '#' },
    ],
    rankingList: []
  }),
  mounted() {
    this.getRankingList();
  }, 
  methods: {
    async submit(form) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post('/ranking/list', form);

        this.getRankingList();
      } catch (e) {
        console.log(e);
      } finally {

        this.isLoading = false;
      }
    },  
    async getRankingList() {
      try {
        const { data } = await this.$http.get('/ranking/list');

        this.rankingList = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>